import React from 'react'

import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'
import SimpleDiv from '../css/SimpleDiv'

const pageTitle = '404: Not Found'

const NotFoundPage = () => {
  return (
    <>
      <FunkyTitle title="404" />
      <SimpleDiv>
        <p>You just hit a page that doesn&#39;t exist...</p>
        <p>
          Either you have done something wrong... or I have... guess we both know
          which is more likely...
        </p>
      </SimpleDiv>

    </>
  )
}

NotFoundPage.Layout = Layout
NotFoundPage.pageTitle = pageTitle

export default NotFoundPage
